import styled, { css } from 'styled-components'
import {
  colors,
  spacing,
  typography,
  radius,
  maxWidths,
  cssEasings,
  mq,
} from '../../styles/utilities/variables'
import caretDown from '../../assets/images/caret-down.svg'
import arrowDown from '../../assets/images/arrow-down.svg'
import { PATH_PREFIX } from '../../helpers/url'

const StyledForm = styled.form`
  max-width: ${maxWidths.base};
  ${props =>
    props.center &&
    css`
      max-width: ${maxWidths.input};
      margin: 0 auto;
    `}

  button {
    width: 100%;
    max-width: 430px;
    margin: 0 auto;
    display: block;
  }

  a {
    color: ${colors.primary};
    border-bottom: 1px solid ${colors.primary};
    width: fit-content;
  }
`
const baseInputStyle = `
  width: 100%;
  background: ${colors.white};
  padding: ${spacing.base} ${spacing.medium};
  font-size: ${typography.sizes.base};
  border-radius: ${radius.input};
  border: 1px solid ${colors.lightGrey};
  position: relative;
  z-index: 2;
  transition: border 0.2s ease-in-out;
  -webkit-appearance: none;
  appearance:none;
  
  @media ${mq.mobile} {
    padding: ${spacing.small} ${spacing.base};
    font-size: ${typography.sizes.small};
  }
`

StyledForm.Input = styled.input`
  ${baseInputStyle};
  max-width: ${maxWidths.input};

  &[name='postalCode'] {
    /* max-width:230px; */
    @media ${mq.mobile} {
      max-width: unset;
    }
  }

  &[type='number'] {
    max-width: 75px;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    border: 1px solid ${colors.primary};
  }

  ${props =>
    props.transparent &&
    css`
      & {
        background: transparent;
        border: none;
        min-width: 150px;
        padding-left: 0;
        padding-right: 0;
      }
    `}
`

const baseCheckboxRadioStyle = `
  position: relative;
  z-index: 2;
  display: flex;
  align-items: flex-start;
  align-items: center;

  .label, .subLabel {
    display:block;
    margin: 0;
    text-transform: none;
    font-size: ${typography.sizes.small};
    max-width: ${maxWidths.base};
    line-height:1.25;
    @media ${mq.mobile} {
      line-height:1.2;
    }
  }

  .label {
    margin-top: -2px;
  }

  .subLabel {
    opacity:0.8;
  }

  input {
    vertical-align: sub;
    margin: 0 ${spacing.small} 0 0;
    width: 20px;
    height: 20px;
    border: 1px solid ${colors.lightGrey};
    appearance: none;
    position: relative;
    background-color: white;
    transition: background-color ${cssEasings.easeInOutCubic} 0.2s;

    &:focus {
      outline: none;
    }

    &:checked ~ .checkmark {
      background-color: ${colors.primary};
      &:after {
        opacity: 1;
      }
    }
    
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    &:after {
      content: "";
      position: absolute;
      opacity: 0;
      transition: opacity 0.2s ${cssEasings.easeInOutCubic};
    }
  }
`

StyledForm.Checkbox = styled.div`
  ${baseCheckboxRadioStyle};

  input {
    border-radius: ${radius.small};
    vertical-align: top;
    &:focus {
      outline: none;
    }

    &:checked ~ .checkmark {
      border-radius: ${radius.small};
    }
  }
  .checkmark:after {
    left: 6px;
    top: 4px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
  .label {
    text-wrap: balance;
  }
  &:has([target='_blank']) {
    .label a {
      position: relative;
    }
    .label a::after {
      content: '';
      position: absolute;
      display: inline-block;
      top: 10%;
      margin-left: ${spacing.xxSmall};
      width: 16px;
      height: 17px;
      background-image: url(${PATH_PREFIX}images/external_link_icon.svg);
      background-repeat: no-repeat;
      background-position: center;
      object-fit: contain;
    }
  }
`

StyledForm.Radio = styled.label`
  ${baseCheckboxRadioStyle};
  margin-bottom: ${spacing.base};
  &:last-child {
    margin-bottom: 0;
  }

  input {
    border-radius: 100%;
    vertical-align: top;

    &:focus {
      outline: none;
    }

    &:checked ~ .checkmark {
      border-radius: 100%;
    }
  }

  .checkmark:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: white;
  }
`

StyledForm.Select = styled.select`
  width:100%;
  position: relative;
  appearance: none;
  outline: none;
  border: 1px solid ${colors.lightGrey};
  background-image: url(${arrowDown});
  background-repeat: no-repeat;
  background-position: calc(100% - 12px) 50%;
  background-size: 12px;
  padding: ${spacing.base} ${spacing.xLarge} ${spacing.base} ${spacing.medium};
  font-size: ${typography.sizes.base};
  border-radius: ${radius.input};
  max-width: ${maxWidths.input};
  color: ${colors.placeholder};

  &:focus {
    outline: none;
  }

  &:focus-visible {
    border: 1px solid ${colors.primary};
  }

  ${props =>
    props.valid &&
    css`
      && {
        border-color: ${colors.green};
      }
    `}

  ${props =>
    props.inValid &&
    css`
      && {
        border-color: ${colors.red};
      }
    `}
  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
  ${props =>
    props.multiple &&
    css`
      & {
        background-image: none;
        padding-right: ${spacing.base};
      }
    `}
`

StyledForm.File = styled.input``

StyledForm.Label = styled.label`
  display: block;
  margin-bottom: ${spacing.xSmall};
  font-size: ${typography.sizes.small};
`

StyledForm.Error = styled.p`
  margin: ${spacing.small} 0 0 0;
  color: ${colors.red};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: ${typography.sizes.small};
  line-height: ${typography.lineHeight};
  svg {
    margin-right: ${spacing.xSmall};
    min-width: 16px;
  }
  span {
    line-height: ${typography.lineHeight};
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`

StyledForm.TextArea = styled.textarea`
  ${baseInputStyle};
  padding: ${spacing.medium};
  min-height: 200px;
`

StyledForm.Group = styled.div`
  margin-bottom: ${spacing.medium};
  @media ${mq.mobile} {
    margin-bottom: ${spacing.base};
  }
  ${props =>
    props.hidden &&
    css`
      display: none;
    `}

  /* &:last-child:not(:has([name='city'])) {
    margin-bottom: 0;
  } */
  width: 100%;
  max-width: ${maxWidths.input};
  &:has([name='firstName']),
  &:has([name='lastName']),
  &:has([name='postalCode']),
  &:has([name='city']),
  &:has([label='Förnamn']),
  &:has([label='Efternamn']),
  &:has([label='Postnummer']),
  &:has([label='Ort']) {
    min-width: 0;
    width: calc(50% - (${spacing.small} / 2));
    max-width: calc((${maxWidths.input} / 2) - (${spacing.small} / 2));
    @media ${mq.mobile} {
      width: 100%;
      max-width: unset;
    }
  }
`

StyledForm.InputWrapper = styled.div`
  position: relative;
  ${props =>
    props.valid &&
    css`
      &:not(:has(input:focus)) {
        input {
          border-color: ${colors.successGreen};
        }
        label {
          color: ${colors.successGreen};
        }
      }
    `}
  ${props =>
    props.inValid &&
    css`
      &:not(:has(input:focus)) {
        input {
          border-color: ${colors.red};
        }
        label {
          color: ${colors.red};
        }
      }
    `}
`
StyledForm.Description = styled.div`
  font-style: italic;
  font-weight: 400;
  line-height: 1.2em;
  margin-top: ${spacing.xxSmall};
  p {
    margin: 0;
    opacity: 0.65;
    line-height: 1.25;
    @media ${mq.mobile} {
      line-height: 1.2;
    }
  }
`

StyledForm.FlyingLabel = styled.label`
  position: absolute;
  top: 50%;
  left: calc(${spacing.medium} - 5px);
  z-index: 3;
  transform: translateY(-50%);
  pointer-events: none;
  transition: font-size 0.2s ease-in-out, top 0.2s ease-in-out;
  color: ${colors.placeholder};
  padding: 1px 5px;
  background: white;
  ${props =>
    props.top &&
    css`
      top: 0;
      font-size: ${typography.sizes.xSmall};
    `}
  @media ${mq.mobile} {
    left: ${spacing.small};
  }
  border-radius: 8px;
`

StyledForm.Section = styled.div`
  background: ${colors.lightestGrey};
  padding: ${spacing.large} ${spacing.xxLarge};
  border-radius: ${radius.input};
  margin-bottom: ${spacing.large};
  z-index: ${props => (props.top ? 1 : 0)};
  position: relative;

  @media ${mq.tablet} {
    padding: ${spacing.large} ${spacing.large};
  }

  @media ${mq.mobile} {
    padding: ${spacing.large} ${spacing.medium};
    width: calc(100vw);
    transform: translateX(-${spacing.medium});
    border-radius: 0;
  }
`

StyledForm.Heading = styled.h4`
  margin-bottom: ${spacing.large};
  font-family: ${typography.primaryFont};
  font-weight: normal;
`

StyledForm.DateWrapper = styled.div`
  /* Style datepicker popup below */
  .react-datepicker {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    border: none;
    font-family: ${typography.secondaryFont};
    font-size: ${typography.sizes.small};

    &::before {
      content: '';
      position: absolute;
      top: 10px;
      left: 10px;
      width: 16px;
      height: 16px;
      border-radius: 999px;
      background: ${colors.secondary};
    }

    &::after {
      content: '';
      position: absolute;
      top: 10px;
      right: 10px;
      width: 16px;
      height: 16px;
      border-radius: 999px;
      background: ${colors.secondary};
    }
  }

  & .react-datepicker-popper {
    margin: 0;
    z-index: 10;
  }

  & .react-datepicker__triangle {
    display: none;
  }

  & .react-datepicker__current-month {
    text-transform: uppercase;
    letter-spacing: 0.04em;
    font-weight: 500;
    font-size: ${typography.sizes.small};
  }

  & .react-datepicker__header {
    background-color: transparent;
    border-bottom: none;
    font-size: ${typography.sizes.small};
    padding-top: 14px;
  }

  & .react-datepicker__navigation--next,
  .react-datepicker__navigation--previous {
    top: 13px;
    height: 8px;
    width: 8px;
    border: 0.5rem solid transparent;

    &:active,
    &:focus {
      outline: none;
    }
    @media ${mq.tablet} {
      top: 14px;
    }
  }

  & .react-datepicker__navigation--next {
    border-left-color: ${colors.primary};
    right: 10px;

    @media ${mq.tablet} {
      right: 11px;
    }
  }

  & .react-datepicker__navigation--previous {
    border-right-color: ${colors.primary};
    left: 10px;

    @media ${mq.tablet} {
      left: 11px;
    }
  }

  & .react-datepicker__day {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 23px;
    width: 23px;
    border-radius: 999px;
    color: ${colors.primary};
    margin: ${spacing.xxxSmall} 6px;
  }

  & .react-datepicker__day--keyboard-selected {
    background: transparent;
  }

  & .react-datepicker__day--today {
    color: ${colors.primary};
    border: 1px solid ${colors.primary};
    background: transparent;
  }

  & .react-datepicker__day--selected {
    color: ${colors.primary};
    background: ${colors.secondary};
  }

  & .react-datepicker__day-names {
    margin-top: ${spacing.xSmall};
  }

  & .react-datepicker__day-name {
    width: 23px;
    margin: ${spacing.xxSmall} 6px;
  }
`
StyledForm.RadioWithContainer = styled.label`
  ${baseCheckboxRadioStyle};
  margin-bottom: ${spacing.medium};
  border: 1px solid ${colors.lightGrey};
  border-radius: ${radius.input};
  padding: ${spacing.midBase} ${spacing.base};
  display: flex;
  align-items: start;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }

  input {
    border-radius: 100%;
    vertical-align: top;

    &:focus {
      outline: none;
    }

    &:checked ~ .checkmark {
      border-radius: 100%;
    }
  }
  .label {
    margin-bottom: ${spacing.xxSmall};
  }
  .subLabel {
    opacity: 0.5;
    font-size: ${typography.sizes.xxSmall};
    line-height: 1.25;
    @media ${mq.mobile} {
      line-height: 1.2;
    }
  }
  .checkmark:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: white;
  }
  @media ${mq.mobile} {
    margin: ${spacing.small} 0;
  }
`

export default StyledForm
